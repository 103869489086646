const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://kb.eyemobile.com.br",
		"gaTrackingId": null
	},
	"firebase": {
		"apiKey": process.env.GATSBY_FIREBASE_API_KEY,
		"authDomain": process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
		"projectId": process.env.GATSBY_FIREBASE_PROJECT_ID,
		"storageBucket": process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
		"databaseURL": process.env.GATSBY_FIREBASE_DATABASE_URL,
		"messagingSenderId": process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
		"appId": process.GATSBY_FIREBASE_APP_ID
	},
	"header": {
		"logo": "https://portal.eyemobile.com.br/app/img/eyemobile_logo_white.svg",
		"logoLink": "/",
		"title": "Base de Conhecimento",
		"githubUrl": "",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": true,
			"indexName": process.env.GATSBY_ALGOLIA_INDEX,
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.GATSBY_ALGOLIA_ADMIN_KEY
		},
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
			'/produto',
			'/faq',
			'/release-notes',
			'/processos'
		],
		"links": [],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Base de Conhecimento | Eyemobile",
		"description": "Documentação e base de conhecimento dos projetos da Eyemobile Tecnologia.",
		"ogImage": null,
		"docsLocation": "",
		"favicon": "./src/assets/favicon.ico"
	},
};

module.exports = config;