import React from 'react';
import { Global, css } from '@emotion/core';

const gCss = css`
  @font-face {
    font-family: Avenir;
    font-weight: 800;
    src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Black.ttf);
  }

  @font-face {
    font-family: Avenir;
    font-weight: 500;
    src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Heavy.ttf);
  }

  @font-face {
    font-family: Avenir;
    font-weight: 300;
    src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Medium.ttf);
  }

  @font-face {
    font-family: Avenir-Roman;
    src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Roman.ttf);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    font-family: Avenir-Roman, Arial, Helvetica, sans-serif;

    font-size: 16px;
  }

  a {
    transition: color 0.15s;
    color: #663399;
  }
`;

const GlobalCss = ({ children }) => {
  return (
    <>
      <Global styles={gCss} />
      {children}
    </>
  );
};

export default GlobalCss;
