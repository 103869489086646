import React, { Component } from 'react';

import getFirebase, { FirebaseContext } from './Firebase';

class FirebaseProvider extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then((values) => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    const { children } = this.props;

    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        {children}
      </FirebaseContext.Provider>
    );
  }
}

export default FirebaseProvider;
